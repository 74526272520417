import { defineStore } from 'pinia'
import {
  DAILY_GROUPS,
  DEFAULT_SETTINGS,
  DISPLAY_MODES,
  VISIBILITY,
} from '@/lib/settings'
import {
  ACCESS_LEVEL,
  restrictionKey,
  keyToCmp,
  loadFractalDailies,
  loadPveDailies,
  loadPvpDailies,
  loadStrikeDailies,
  loadWvwDailies,
} from '@/lib/dailyLoader'
import { captureException } from '@sentry/vue'

export const useStore = defineStore('store', {
  state: () => ({
    settings: {},
    snackbar: {
      snackbarText: '',
      snackbarVisible: false,
    },
    currentTab: {
      dailies: [],
      error: '',
    },
  }),
  getters: {
    // Settings getters
    isRestrictionCompatible: (state) => (restriction) => {
      const hasHot = state.settings.accountAccess.hot
      const hasPof = state.settings.accountAccess.pof
      const hasEod = state.settings.accountAccess.eod
      const maxCharLevel = state.settings.accountAccess.maxCharLevel

      const minDailyLevel = restriction.level.min
      const maxDailyLevel = restriction.level.max
      const access = restriction.accessLevel

      if (maxCharLevel < minDailyLevel) {
        return false
      }

      if (maxCharLevel > maxDailyLevel) {
        return false
      }

      if (access === ACCESS_LEVEL.ALL_ACCESS) {
        return true
      }

      if (access === ACCESS_LEVEL.HOT_ONLY) {
        return hasHot
      }

      if (access === ACCESS_LEVEL.POF_ONLY) {
        return hasPof
      }

      if (access === ACCESS_LEVEL.EOD_ONLY) {
        return hasEod
      }

      if (access === ACCESS_LEVEL.NOT_HOT) {
        return !hasHot
      }

      if (access === ACCESS_LEVEL.NOT_POF) {
        return !hasPof
      }

      if (access === ACCESS_LEVEL.NOT_EOD) {
        return !hasEod
      }

      return true
    },
    isCompatible: (state) => (daily) => {
      if (!daily.restrictions) {
        return true
      }

      for (const restriction of daily.restrictions) {
        if (this.isRestrictionCompatible(state.settings, restriction)) {
          return true
        }
      }

      return false
    },
    getDailyVisibility: (state) => (daily) => {
      if (state.settings.displayMode === DISPLAY_MODES.SHOW_ALL) {
        return VISIBILITY.VISIBLE
      }

      const compatible = this.isCompatible(state.settings, daily)

      if (compatible) {
        return VISIBILITY.VISIBLE
      }

      if (state.settings.displayMode === DISPLAY_MODES.HIGHLIGHT_MINE) {
        return VISIBILITY.GREYED_OUT
      }

      return VISIBILITY.HIDDEN
    },

    // Dailies getters
    getSortedDailies: (state) => (dailies) => {
      dailies.sort(
        keyToCmp((d) => {
          return [state.isCompatible(state.settings, d) ? 0 : 1] // compatible first
            .concat(restrictionKey(d.restrictions[0])) // then sort by restriction-ness
            .concat([d.title]) // break ties by title
        }),
      )
      return dailies
    },
  },
  actions: {
    // Settings actions
    getSettings() {
      let settings
      if (localStorage.settings) {
        settings = JSON.parse(localStorage.settings)

        settings = { ...DEFAULT_SETTINGS, ...settings }

        if (settings.version === undefined) {
          // very old
          settings = DEFAULT_SETTINGS
        } else if (settings.version === 1) {
          settings.accountAccess.eod = true
          settings.version = 2
        }
      } else {
        settings = DEFAULT_SETTINGS
      }
      this.settings = settings
    },

    saveSettings(newSettings) {
      this.settings = newSettings
      localStorage.settings = JSON.stringify(newSettings)
    },

    // Snackbar actions
    showSnackbar(event) {
      this.snackbar.snackbarText = event.message
      this.snackbar.snackbarVisible = true
    },

    // Dailies actions
    async getDailies(url, date) {
      this.currentTab.error = ''
      try {
        const response = await fetch(url)
        const rawData = await response.json()
        this.currentTab.dailies = {
          [DAILY_GROUPS.PVE]: this.getSortedDailies(
            loadPveDailies(this.settings, rawData),
          ),
          [DAILY_GROUPS.FRACTALS]: loadFractalDailies(rawData),
          [DAILY_GROUPS.PVP]: this.getSortedDailies(
            loadPvpDailies(this.settings, rawData),
          ),
          [DAILY_GROUPS.WVW]: this.getSortedDailies(
            loadWvwDailies(this.settings, rawData),
          ),
          [DAILY_GROUPS.STRIKES]: loadStrikeDailies(date),
        }
      } catch (err) {
        this.currentTab.error = err.toString()
        captureException(err)
      }
    },
  },
})
