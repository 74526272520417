<template>
  <v-hover :disabled="data.simple">
    <template #default="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        v-on="data.simple ? {} : { click: copyToClipboard }">
        <v-img
          :src="getImageUrl(data.art)"
          :aspect-ratio="aspectRatio"
          :class="greyOut ? 'grey-out' : ''"
          cover>
          <v-row align="end" class="fill-height ma-0">
            <span class="card-title">
              {{ data.title }}
              <v-spacer />
              <span v-if="restrictionText" class="card-subtitle">
                {{ restrictionText }}
              </span>
            </span>
          </v-row>
        </v-img>

        <v-list v-if="!data.simple" lines="2" class="pt-0">
          <v-list-item
            v-if="waypoint"
            prepend-icon="layers"
            :title="waypoint.title"
            :subtitle="waypoint.chatlink" />

          <v-list-item
            v-if="poi"
            prepend-icon="location_on"
            :title="poi.title"
            :subtitle="poi.chatlink" />

          <v-list-item
            v-if="vista"
            prepend-icon="filter_hdr"
            :title="vista.title"
            :subtitle="vista.chatlink" />

          <v-list-item v-if="timer" prepend-icon="timer">
            <v-list-item-title>
              <BossTimer
                :start-time="timer.start_time"
                :repeat-time="timer.repeat_time" />
            </v-list-item-title>
            <v-list-item-subtitle v-if="data.show_timer_title">
              {{ data.timer }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>

        <v-card-text v-if="data.hints">
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-for="(hint, idx) in data.hints"
            :key="'hint' + idx"
            class="text--primary"
            v-html="hint" />
          <!--eslint-enable-->
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { ACCESS_LEVEL } from '../lib/dailyLoader'
import BossTimer from './BossTimer.vue'
import POIS from '../lib/data/pve/points_of_interest.json'
import TIMERS from '../lib/data/pve/boss_timers.json'
import VISTAS from '../lib/data/pve/vistas.json'
import WAYPOINTS from '../lib/data/pve/waypoints.json'
import { makeMapLink } from '../lib/chatLink'
import { DateTime } from 'luxon'
import { nextSpawn } from '../lib/bossTimer'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  aspectRatio: {
    type: Number,
    default: 2,
  },
  greyOut: {
    type: Boolean,
    default: false,
  },
})

const formatLevel = (level) =>
  level.min === level.max
    ? level.min.toString()
    : level.min.toString() + '-' + level.max.toString()

const formatRestriction = (r) => {
  let text = ''

  if (r.level.min !== 1 || r.level.max !== 80) {
    text += 'level\xa0' + formatLevel(r.level)
  }

  if (r.accessLevel === ACCESS_LEVEL.POF_ONLY) {
    text += '\xa0(PoF)'
  } else if (r.accessLevel === ACCESS_LEVEL.HOT_ONLY) {
    text += '\xa0(HoT)'
  } else if (r.accessLevel === ACCESS_LEVEL.NOT_POF) {
    text += '\xa0(no PoF)'
  } else if (r.accessLevel === ACCESS_LEVEL.NOT_HOT) {
    text += '\xa0(no HoT)'
  }

  return text
}

const getImageUrl = function (name) {
  return new URL(`../assets/${name}.jpg`, import.meta.url).href
}

const restrictionText = ref()
const waypoint = ref()
const poi = ref()
const vista = ref()
const timer = ref()

onMounted(() => {
  const restrictions = props.data.restrictions
  restrictionText.value =
    restrictions === undefined
      ? null
      : restrictions.map(formatRestriction).join(', ')

  const waypointId = props.data.waypoint
  waypoint.value =
    waypointId === undefined
      ? null
      : {
          title: WAYPOINTS[waypointId] || 'Unknown waypoint',
          chatlink: makeMapLink(waypointId),
        }

  const poiId = props.data.poi
  poi.value =
    poiId === undefined
      ? null
      : {
          title: POIS[poiId] || 'Unknown PoI',
          chatlink: makeMapLink(poiId),
        }

  const vistaId = props.data.vista
  vista.value =
    vistaId === undefined
      ? null
      : {
          title: VISTAS[vistaId] || 'Unknown vista',
          chatlink: makeMapLink(vistaId),
        }

  const name = props.data.timer
  timer.value = name === undefined ? null : TIMERS[name]
})

const clipboardText = computed(() => {
  if (props.data.simple) {
    return null
  }

  let result = ''

  if (restrictionText.value) {
    result += '(' + restrictionText.value + ') '
  }

  result += props.data.title

  if (waypoint.value) {
    result += ' > ' + waypoint.value.chatlink
  }

  if (poi.value) {
    result += ' > ' + poi.value.chatlink
  }

  if (vista.value) {
    result += ' > ' + vista.value.title + ' ' + vista.value.chatlink
  }

  if (timer.value) {
    result +=
      ' (' +
      nextSpawn(DateTime.utc(), timer.value.start_time, timer.value.repeat_time)
        .diff(DateTime.utc())
        .toFormat('in hh:mm') +
      ')'
  }

  return result
})

const store = useStore()

const copyToClipboard = async () => {
  if (props.data.simple) {
    return
  }

  try {
    await navigator.clipboard.writeText(clipboardText.value)
    store.showSnackbar({ message: 'Copied to clipboard' })
  } catch {
    store.showSnackbar({ message: 'Failed to copy to clipboard' })
  }
}

defineExpose({ clipboardText })
</script>

<style scoped>
* {
  user-select: none;
}

.card-title {
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  padding: 16px 24px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.card-subtitle {
  padding-left: 8px;
  font-size: 1rem;
  font-weight: 300;
  flex-shrink: 0;
  align-self: center;
}

.grey-out {
  filter: grayscale(100%);
}
</style>
