<script setup>
import { inject, provide, ref, computed, onBeforeMount } from 'vue'
import { useTheme } from 'vuetify'
import { useStore } from '@/store'
import DailyTab from '@/components/DailyTab.vue'
import Settings from '@/components/SettingsWindow.vue'

const version = inject('version')

const store = useStore()
const settings = computed(() => store.settings)

const theme = useTheme()

onBeforeMount(() => {
  store.getSettings()
  theme.global.name.value = settings.value.dark ? 'dark' : 'light'
})

const drawerOpen = ref(false)
const settingsVisible = ref(false)

const showSettings = () => {
  drawerOpen.value = false
  settingsVisible.value = true
}

const saveSettingsInApp = (newSettings) => {
  settingsVisible.value = false
  theme.global.name.value = newSettings.dark ? 'dark' : 'light'
  store.saveSettings(newSettings)
}

const closeSettings = () => {
  settingsVisible.value = false
}

provide('settings', {
  showSettings,
  closeSettings,
  saveSettings: saveSettingsInApp,
})

const snackbar = computed(() => store.snackbar)

const todayTab = ref()
const tomorrowTab = ref()

const active = ref(0)

const copyAll = () => {
  if (active.value) {
    tomorrowTab.value.copyAll()
  } else {
    todayTab.value.copyAll()
  }
}
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="drawerOpen" app temporary>
      <template #prepend>
        <v-list-item
          prepend-icon="help"
          title="gw2.tools"
          :subtitle="'Version ' + version" />
        <v-divider />
      </template>

      <v-list dense>
        <v-list-item link prepend-icon="settings" @click="showSettings">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list>

      <template #append>
        <v-list dense>
          <v-list-subheader>MAINTAINED BY</v-list-subheader>

          <v-list-item
            prepend-avatar="https://www.gravatar.com/avatar/69e3162e5c16812a9540db32f1ff9cec.jpg"
            title="K900"
            subtitle="MehWhatever.1248" />

          <v-list-subheader>HINTS COLLECTED FROM</v-list-subheader>

          <v-list-item
            link
            href="https://heya.im/"
            target="_blank"
            prepend-icon="pets"
            title="Stomp Agenda" />

          <v-list-item
            link
            href="https://reddit.com/r/GuildWars2"
            target="_blank"
            prepend-icon="reddit"
            title="/r/GuildWars2" />

          <v-list-subheader>FIND US ON</v-list-subheader>

          <v-list-item
            link
            href="https://gitlab.com/gw2tools/gw2tools.gitlab.io"
            target="_blank"
            prepend-icon="mdi:mdi-gitlab"
            title="GitLab">
          </v-list-item>

          <v-list-item
            link
            href="https://discord.gg/PZwaU9K"
            target="_blank"
            prepend-icon="discord"
            title="Discord" />

          <v-list-item
            link
            href="https://t.me/k900x"
            target="_blank"
            prepend-icon="telegram"
            title="Telegram" />
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawerOpen = true" />

      <v-toolbar-title> gw2.tools </v-toolbar-title>

      <v-spacer />

      <!-- For unknown reason append-icon doesn't work here -->
      <v-btn icon rounded @click="showSettings">
        <v-icon>settings</v-icon>
      </v-btn>

      <template #extension>
        <v-tabs v-model="active" align-with-title>
          <v-tab key="today"> Today </v-tab>
          <v-tab key="tomorrow"> Tomorrow </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-window v-model="active">
        <v-window-item key="today">
          <daily-tab
            ref="todayTab"
            url="https://api.guildwars2.com/v2/achievements/daily"
            date="today" />
        </v-window-item>

        <v-window-item key="tomorrow">
          <daily-tab
            ref="tomorrowTab"
            url="https://api.guildwars2.com/v2/achievements/daily/tomorrow"
            date="tomorrow" />
        </v-window-item>
      </v-window>

      <v-card-text>
        <v-tooltip location="start" top>
          <template #activator="{ props }">
            <v-fab-transition>
              <!-- For unknown reason append-icon doesn't work here -->
              <v-btn
                rounded
                position="fixed"
                location="bottom right"
                icon
                color="accent m-5"
                v-bind="props"
                class="ma-4"
                @click="copyAll">
                <v-icon>content_copy</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>Copy all</span>
        </v-tooltip>
      </v-card-text>
    </v-main>

    <v-dialog v-model="settingsVisible" scrollable persistent width="600px">
      <Settings />
    </v-dialog>

    <v-snackbar
      v-model="snackbar.snackbarVisible"
      :timeout="1000"
      app
      location="bottom">
      {{ snackbar.snackbarText }}
      <template #actions>
        <v-btn text @click="snackbar.snackbarVisible = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style>
@import 'vuetify/lib/styles/main.css';
</style>
