<script setup>
import { ref, computed } from 'vue'
import { useStore } from '@/store'
import { VISIBILITY } from '@/lib/settings'
import DailyCard from '@/components/DailyCard.vue'

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  copyable: {
    type: Boolean,
    default: false,
  },
  cardWidth: {
    type: Number,
    default: 4,
  },
  cardAspectRatio: {
    type: Number,
    default: 2,
  },
})

const store = useStore()
const settings = computed(() => store.settings)

const dailies = computed(() =>
  settings.value.accountAccess
    ? props.data
        .map((d) => ({
          data: d,
          visibility: store.getDailyVisibility(d),
        }))
        .filter((d) => d.visibility !== VISIBILITY.HIDDEN)
        .map((d) => ({
          data: d.data,
          shouldGreyOut: d.visibility === VISIBILITY.GREYED_OUT,
        }))
    : [],
)

const cards = ref([])

defineExpose({ cards })
</script>

<template>
  <v-row>
    <v-col
      v-for="daily in dailies"
      :key="daily.data.id"
      cols="12"
      md="6"
      :lg="cardWidth">
      <DailyCard
        ref="cards"
        :data="daily.data"
        :aspect-ratio="cardAspectRatio"
        :grey-out="daily.shouldGreyOut" />
    </v-col>
  </v-row>
</template>
