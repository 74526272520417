'use strict'

function uint16ToByteStringLE(val) {
  const lo = val & 0xff
  const hi = (val >> 8) & 0xff
  return String.fromCharCode(lo) + String.fromCharCode(hi)
}

function makeMapLink(id) {
  const bdata = '\x04' + uint16ToByteStringLE(id) + '\x00\x00'
  return '[&' + btoa(bdata) + ']'
}

export { makeMapLink }
