<script setup>
import { ref, computed, inject, onBeforeMount } from 'vue'
import { useStore } from '@/store'
import { DAILY_GROUPS } from '@/lib/settings'
import InfoBanner from '@/components/InfoBanner.vue'
import DailyGroupC from '@/components/DailyGroup.vue'
import Error from '@/components/ErrorPage.vue'

// const props = defineProps({
//   url: {
//     type: String,
//     required: true,
//   },
//   date: {
//     type: String,
//     required: true,
//   },
// })

const loaded = ref(false)

const store = useStore()
const error = computed(() => store.currentTab.error)

onBeforeMount(async () => {
  loaded.value = false
  // FIXME: disabled because API is on fire
  // await store.getDailies(props.url + '?v=latest', props.date)
  // loaded.value = true
})

const { showSettings } = inject('settings')

const settings = computed(() => store.settings)

const DISPLAY_SETTINGS = Object.freeze({
  [DAILY_GROUPS.PVE]: {
    aspectRatio: 2,
    width: 3,
    copyable: true,
  },
  [DAILY_GROUPS.FRACTALS]: {
    aspectRatio: 3,
    width: 4,
    copyable: false,
  },
  [DAILY_GROUPS.PVP]: {
    aspectRatio: 2.5,
    width: 3,
    copyable: false,
  },
  [DAILY_GROUPS.WVW]: {
    aspectRatio: 2.5,
    width: 3,
    copyable: false,
  },
  [DAILY_GROUPS.STRIKES]: {
    aspectRatio: 15,
    width: 12,
    copyable: false,
  },
})

const dailies = computed(() => store.currentTab.dailies)

const groups = computed(() =>
  settings.value?.categories?.filter((o) => o.enabled),
)

const groupComponents = ref([])

const copyAll = async () => {
  const result = ['', "# Today's dailies:"]

  groupComponents.value
    .filter((g, id) => DISPLAY_SETTINGS[groups.value[id].id].copyable)
    .forEach((g) => {
      g.cards.forEach((c) => result.push(c.clipboardText))
    })

  result.push('')
  result.push('-- http://gw2.tools/')
  try {
    await navigator.clipboard.writeText(result.join('\n'))
    store.showSnackbar({ message: 'Copied to clipboard' })
  } catch {
    store.showSnackbar({ message: 'Failed to copy to clipboard' })
  }
}

defineExpose({ copyAll })
</script>

<template>
  <div>
    <InfoBanner />

    <template v-if="!loaded">
      <div class="d-flex justify-center mt-13">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </template>

    <template v-if="error">
      <Error>
        <p>{{ error }}</p>
      </Error>
    </template>

    <template v-if="loaded && !error">
      <v-container fluid class="mb-8">
        <template v-for="(group, idx) in groups" :key="group.id">
          <DailyGroupC
            ref="groupComponents"
            :data="dailies[group.id]"
            :card-aspect-ratio="DISPLAY_SETTINGS[group.id].aspectRatio"
            :card-width="DISPLAY_SETTINGS[group.id].width"
            :copyable="DISPLAY_SETTINGS[group.id].copyable" />
          <v-divider
            v-if="idx != groups.length - 1"
            :key="group.id.toString() + '-divider'"
            class="row-divider" />
        </template>

        <template v-if="groups?.length === 0">
          <Error>
            <p>You turned everything off, so there's nothing here.</p>
            <p>Go turn something back on.</p>
            <v-btn @click="showSettings">Settings</v-btn>
          </Error>
        </template>
      </v-container>
    </template>
  </div>
</template>

<style scoped>
.row-divider {
  margin: 15px 75px 12px;
}
</style>
