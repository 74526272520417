import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { registerSW } from 'virtual:pwa-register'

import colors from 'vuetify/lib/util/colors'

import App from './App.vue'
import { createVuetify } from 'vuetify'
import { createPinia } from 'pinia'

import { md, aliases } from 'vuetify/lib/iconsets/md'
import { mdi } from 'vuetify/lib/iconsets/mdi'
import { fa } from 'vuetify/lib/iconsets/fa'

import { version } from '../package.json'

registerSW()

const app = createApp(App)

app.provide('version', version)

const vuetify = createVuetify({
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        dark: true,
        colors: {
          primary: colors.red.darken3,
          accent: colors.red.darken4,
        },
      },
      light: {
        colors: {
          primary: colors.red.darken3,
          accent: colors.red.darken4,
        },
      },
    },
  },
})
app.use(vuetify)

const pinia = createPinia()
app.use(pinia)

Sentry.init({
  app,
  dsn: 'https://0684f9db7b554291bd59ef4f39377f1f@sentry.io/5184472',
  release: version,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
})

app.mount('#app')
