<script setup>
import { ref, inject, onMounted, computed } from 'vue'
import { useStore } from '@/store'
import { DAILY_GROUPS, DISPLAY_MODES } from '@/lib/settings'
import Draggable from 'vuedraggable'

const dark = ref(true)

const displayMode = ref({
  options: [
    {
      id: DISPLAY_MODES.SHOW_ALL,
      title: 'Show all dailies',
      subtitle: 'All dailies will always be visible.',
    },
    {
      id: DISPLAY_MODES.HIGHLIGHT_MINE,
      title: 'Highlight my dailies',
      subtitle:
        "Dailies you can't access will be greyed out, but still visible and copyable.",
    },
    {
      id: DISPLAY_MODES.ONLY_MINE,
      title: 'Show only my dailies',
      subtitle: "Dailies you can't access will be hidden entirely.",
    },
  ],
  active: DISPLAY_MODES.SHOW_ALL,
})

const accountAccess = ref({
  hot: true,
  pof: true,
  eod: true,
  maxCharLevel: 80,
})

const switchAccess = (value) => {
  accountAccess.value[value] = !accountAccess.value[value]
}

const categories = ref({
  names: {
    [DAILY_GROUPS.PVE]: 'PvE',
    [DAILY_GROUPS.FRACTALS]: 'Fractals',
    [DAILY_GROUPS.PVP]: 'PvP',
    [DAILY_GROUPS.WVW]: 'WvW',
    [DAILY_GROUPS.STRIKES]: 'Strikes',
  },
  options: [
    {
      id: DAILY_GROUPS.PVE,
      enabled: true,
    },
    {
      id: DAILY_GROUPS.FRACTALS,
      enabled: true,
    },
    {
      id: DAILY_GROUPS.PVP,
      enabled: true,
    },
    {
      id: DAILY_GROUPS.WVW,
      enabled: true,
    },
    {
      id: DAILY_GROUPS.STRIKES,
      enabled: true,
    },
  ],
})

const switchCategory = (option) => {
  const foundOption = categories.value.options.find((o) => o.id === option.id)
  foundOption.enabled = !foundOption.enabled
}

const { closeSettings, saveSettings } = inject('settings')

const store = useStore()
const settings = computed(() => store.settings)

const setSettings = () => {
  dark.value = settings.value.dark
  displayMode.value.active = settings.value.displayMode
  accountAccess.value.hot = settings.value.accountAccess.hot
  accountAccess.value.pof = settings.value.accountAccess.pof
  accountAccess.value.eod = settings.value.accountAccess.eod
  accountAccess.value.maxCharLevel = settings.value.accountAccess.maxCharLevel
  categories.value.options = settings.value.categories.map((o) =>
    Object.assign({}, o),
  )
}

onMounted(() => {
  setSettings()
})

const saveNewSettings = () => {
  saveSettings({
    version: 2,
    dark: dark.value,
    displayMode: displayMode.value.active,
    accountAccess: {
      hot: accountAccess.value.hot,
      pof: accountAccess.value.pof,
      eod: accountAccess.value.eod,
      maxCharLevel: accountAccess.value.maxCharLevel,
    },
    categories: categories.value.options.map((o) => ({
      id: o.id,
      enabled: o.enabled,
    })),
  })
}

const closeDialog = () => {
  closeSettings()
  setSettings()
}
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Settings</span>
    </v-card-title>
    <v-card-text>
      <v-list class="pb-0">
        <v-list-subheader>General settings</v-list-subheader>

        <v-list-item value="dark" title="Dark theme" @click="dark = !dark">
          <template #prepend>
            <v-list-item-action>
              <v-checkbox v-model="dark" color="accent" hide-details />
            </v-list-item-action>
          </template>
        </v-list-item>

        <!-- Have to add class, cause radio-group has no top margin anymore -->
        <v-radio-group v-model="displayMode.active" class="mt-4">
          <!-- Lines does not work here for some reason, so have to use min-height -->
          <v-list-item
            v-for="option in displayMode.options"
            :key="option.id"
            :subtitle="option.subtitle"
            :title="option.title"
            min-height="64"
            @click="displayMode.active = option.id">
            <template #prepend="{ isActive }">
              <v-list-item-action>
                <v-radio
                  :value="option.id"
                  :model-value="isActive"
                  color="accent" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-radio-group>
      </v-list>

      <v-list class="compact">
        <v-list-subheader>Available expansions</v-list-subheader>

        <v-list-item
          value="hot"
          title="Heart of Thorns"
          @click="switchAccess('hot')">
          <template #prepend>
            <v-list-item-action>
              <v-checkbox
                v-model="accountAccess.hot"
                color="accent"
                hide-details
                @click.prevent />
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-list-item
          value="pof"
          title="Path of Fire"
          @click="switchAccess('pof')">
          <template #prepend>
            <v-list-item-action>
              <v-checkbox
                v-model="accountAccess.pof"
                color="accent"
                hide-details
                @click.prevent />
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-list-item
          value="eod"
          title="End of Dragons"
          @click="switchAccess('eod')">
          <template #prepend>
            <v-list-item-action>
              <v-checkbox
                v-model="accountAccess.eod"
                color="accent"
                hide-details
                @click.prevent />
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-list-subheader>Maximum character level</v-list-subheader>

        <v-list-item>
          <v-slider
            v-model="accountAccess.maxCharLevel"
            min="1"
            max="80"
            step="1"
            track-size="2"
            thumb-size="12"
            color="accent"
            hide-details>
            <template #prepend>
              <span class="mr-3">
                {{ accountAccess.maxCharLevel.toString() }}
              </span>
            </template>
          </v-slider>
        </v-list-item>
      </v-list>

      <v-list class="compact">
        <v-list-subheader>Categories and ordering</v-list-subheader>
        <Draggable
          v-model="categories.options"
          item-key="id"
          handle=".handle"
          ghost-class="ghost">
          <template #item="{ element }">
            <v-list-item
              :value="element.id"
              :title="categories.names[element.id]"
              @click="switchCategory(element)">
              <template #prepend>
                <v-list-item-action>
                  <v-checkbox
                    v-model="element.enabled"
                    color="accent"
                    hide-details
                    @click.prevent />
                </v-list-item-action>
              </template>
              <template #append>
                <v-list-item-action>
                  <!-- Added style to handle in light theme just for better look -->
                  <v-icon
                    class="handle"
                    :class="settings.dark ? '' : 'text-grey-darken-1'">
                    reorder
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </Draggable>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="closeDialog">Close</v-btn>
      <v-btn text @click="saveNewSettings">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.ghost {
  opacity: 0.5;
}

/* Selected list item has permanent background, so we remove it */
:deep(.v-list-item--active > .v-list-item__overlay) {
  opacity: 0 !important;
}
:deep(.v-list-item:hover > .v-list-item__overlay) {
  opacity: 0.04 !important;
}

/* To make some checkboxes be more compact */
.compact .v-input--density-default {
  --v-input-control-height: 40px;
}
</style>
