<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { DateTime } from 'luxon'
import { nextSpawn } from '@/lib/bossTimer'

const props = defineProps({
  startTime: {
    type: Array,
    required: true,
  },
  repeatTime: {
    type: Array,
    required: true,
  },
})

const now = ref(DateTime.utc())
const next = ref(DateTime.utc())

const getNextSpawn = (now) => nextSpawn(now, props.startTime, props.repeatTime)

let interval

onMounted(() => {
  interval = setInterval(() => {
    now.value = DateTime.utc()
    if (now.value >= next.value) {
      next.value = getNextSpawn(now.value)
    }
  }, 1000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<template>
  <span>
    {{ next.diff(now).toFormat('in hh:mm') }} /
    {{ next.toLocal().toFormat('HH:mm') }}
  </span>
</template>
