<template>
  <v-alert color="yellow" type="warning" closable close-icon="cancel">
    <strong>Note:</strong> The API is back to operating, but the daily system
    has changed so we can't get information on that yet. Bear with us! 🐻
  </v-alert>
</template>

<style scoped>
.v-alert {
  border-radius: 0 !important;
}
.v-alert.v-theme--light {
  color: #424242 !important;
  background-color: rgba(255, 235, 59, 0.5) !important;
}
.v-alert.v-theme--dark {
  color: rgb(255, 235, 59) !important;
  background-color: rgba(255, 235, 59, 0.1) !important;
}
</style>
