'use strict'

import { DateTime } from 'luxon'

import FRACTAL_ACHIEVEMENTS_DAILY from './data/fractals/daily_achievements.json'
import FRACTAL_ACHIEVEMENTS_RECOMMENDED from './data/fractals/recommended_achievements.json'
import FRACTAL_SCALE_TO_NAME from './data/fractals/scales.json'

import PVE_DAILY_DATA from './data/pve/dailies.json'
import PVP_DAILY_DATA from './data/pvp/dailies.json'
import WVW_DAILY_DATA from './data/wvw/dailies.json'

import STRIKE_ROTATION from './data/strikes/rotation.json'

function invertMap(map) {
  const result = {}

  for (const k in map) {
    if (Object.hasOwnProperty.call(map, k)) {
      const ids = map[k]
      for (const v in ids) {
        if (Object.hasOwnProperty.call(ids, v)) {
          result[ids[v]] = k
        }
      }
    }
  }

  return result
}

const FRACTAL_ACHIEVEMENT_TO_NAME = invertMap(FRACTAL_ACHIEVEMENTS_DAILY)

function makeSimpleDaily(title, art) {
  return {
    title,
    art,
    simple: true,
  }
}

function makeUnknownDaily(id) {
  return makeSimpleDaily('Unknown daily ' + id, 'nope')
}

const ACCESS_LEVEL = Object.freeze({
  ALL_ACCESS: 0,
  POF_ONLY: 1,
  HOT_ONLY: 2,
  NOT_POF: 3,
  NOT_HOT: 4,
  EOD_ONLY: 5,
  NOT_EOD: 6,
  UNKNOWN: 7,
})

function getAccessLevel(daily) {
  const access = daily.required_access

  if (access === undefined) {
    return ACCESS_LEVEL.ALL_ACCESS
  }

  const product = access.product
  const condition = access.condition === 'HasAccess'

  if (product === 'PathOfFire') {
    if (condition) {
      return ACCESS_LEVEL.POF_ONLY
    } else {
      return ACCESS_LEVEL.NOT_POF
    }
  }

  if (product === 'HeartOfThorns') {
    if (condition) {
      return ACCESS_LEVEL.HOT_ONLY
    } else {
      return ACCESS_LEVEL.NOT_HOT
    }
  }

  if (product === 'EndOfDragons') {
    if (condition) {
      return ACCESS_LEVEL.EOD_ONLY
    } else {
      return ACCESS_LEVEL.NOT_EOD
    }
  }

  console.warn('Failed to determine access level for daily:', daily)

  return ACCESS_LEVEL.UNKNOWN
}

function restrictionKey(restriction) {
  return [
    -restriction.level.max, // highest max level (80) dailies go first
    restriction.accessLevel, // sort by access level:
    // shared first, then PoF, then HoT, then !PoF, then !HoT
    restriction.level.min, // prefer lower start level
  ]
}

function keyToCmp(key) {
  return function (a, b) {
    const ka = key(a)
    const kb = key(b)

    for (let i = 0; i < ka.length; i++) {
      if (ka[i] > kb[i]) return 1
      if (ka[i] < kb[i]) return -1
    }

    return 0
  }
}

function loadDailiesImpl(settings, apiDailies, dict, hook) {
  if (apiDailies === undefined) {
    return
  }

  const restrictionsById = {}
  apiDailies.forEach((el) => {
    restrictionsById[el.id] = restrictionsById[el.id] || []

    if (el.level !== undefined) {
      // stubs can have no restrictions
      restrictionsById[el.id].push({
        level: el.level,
        accessLevel: getAccessLevel(el),
      })
    }
  })

  const dailies = []

  for (const id in restrictionsById) {
    if (!Object.hasOwnProperty.call(restrictionsById, id)) {
      continue
    }

    let data = dict[id]

    if (data === undefined) {
      data = makeUnknownDaily(id)
    } else {
      data = hook(data)
    }

    data = Object.assign({}, data)

    const restrictions = restrictionsById[id]
    restrictions.sort(keyToCmp(restrictionKey))

    data.restrictions = restrictions
    dailies.push(Object.freeze(data))
  }

  return dailies
}

function loadPveDailies(settings, apiDailies) {
  return loadDailiesImpl(settings, apiDailies.pve, PVE_DAILY_DATA, (a) => a)
}

function loadPvpDailies(settings, apiDailies) {
  return loadDailiesImpl(settings, apiDailies.pvp, PVP_DAILY_DATA, (a) =>
    makeSimpleDaily(a, 'pvp'),
  )
}

function loadWvwDailies(settings, apiDailies) {
  return loadDailiesImpl(settings, apiDailies.wvw, WVW_DAILY_DATA, (a) =>
    makeSimpleDaily(a, 'wvw'),
  )
}

function loadFractalDailies(apiDailies) {
  const recommended = []
  const daily = new Set()
  const errors = []

  if (apiDailies.fractals === undefined) {
    return
  }

  apiDailies.fractals.forEach((el) => {
    const id = el.id
    if (id in FRACTAL_ACHIEVEMENTS_RECOMMENDED) {
      recommended.push(FRACTAL_ACHIEVEMENTS_RECOMMENDED[id])
    } else if (id in FRACTAL_ACHIEVEMENT_TO_NAME) {
      daily.add(FRACTAL_ACHIEVEMENT_TO_NAME[id])
    } else {
      errors.push(makeUnknownDaily(id))
    }
  })

  recommended.sort((a, b) => a - b)

  const dailyList = []

  daily.forEach((el) => dailyList.push(el))

  dailyList.sort()

  const dailyResults = dailyList.map((el) => makeSimpleDaily(el, 'fractals'))
  const recResults = recommended.map((el) =>
    makeSimpleDaily(
      'Recommended ' + el.toString() + ' - ' + FRACTAL_SCALE_TO_NAME[el],
      'fractals',
    ),
  )

  return dailyResults.concat(recResults).concat(errors)
}

function loadStrikeDailies(date) {
  const epoch = DateTime.utc(1970, 1, 1, 0, 0, 0)
  let now = DateTime.utc()

  if (date === 'tomorrow') {
    now = now.plus({ days: 1 })
  }

  const days = Math.floor(now.diff(epoch).as('days'))
  const strike = STRIKE_ROTATION[days % STRIKE_ROTATION.length]

  return [makeSimpleDaily(strike, 'strikes')]
}

export {
  restrictionKey,
  keyToCmp,
  loadFractalDailies,
  loadPveDailies,
  loadPvpDailies,
  loadStrikeDailies,
  loadWvwDailies,
  ACCESS_LEVEL,
}
