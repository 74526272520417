<template>
  <div class="error-container mt-13 w-100 text-center">
    <section>
      <v-icon> error_outline </v-icon>
      <h4>Error</h4>
      <slot />
    </section>
  </div>
</template>

<style scoped>
.error-container i {
  font-size: 72pt;
}
</style>
