const DISPLAY_MODES = Object.freeze({
  SHOW_ALL: 0,
  HIGHLIGHT_MINE: 1,
  ONLY_MINE: 2,
})

const DAILY_GROUPS = Object.freeze({
  PVE: 0,
  FRACTALS: 1,
  PVP: 2,
  WVW: 3,
  STRIKES: 4,
})

const VISIBILITY = Object.freeze({
  VISIBLE: 0,
  GREYED_OUT: 1,
  HIDDEN: 2,
})

const DEFAULT_SETTINGS = {
  version: 2,
  dark: true,
  displayMode: DISPLAY_MODES.SHOW_ALL,
  accountAccess: {
    hot: true,
    pof: true,
    eod: true,
    maxCharLevel: 80,
  },
  categories: [
    { id: DAILY_GROUPS.PVE, enabled: true },
    { id: DAILY_GROUPS.FRACTALS, enabled: true },
    { id: DAILY_GROUPS.PVP, enabled: true },
    { id: DAILY_GROUPS.WVW, enabled: true },
    { id: DAILY_GROUPS.STRIKES, enabled: true },
  ],
}

export { DISPLAY_MODES, DAILY_GROUPS, VISIBILITY, DEFAULT_SETTINGS }
