import { DateTime } from 'luxon'

function nextSpawn(now, startTime, repeatTime) {
  let then = DateTime.utc().set({
    hours: startTime[0],
    minutes: startTime[1],
    seconds: 0,
    milliseconds: 0,
  })

  while (then < now) {
    then = then.plus({ hours: repeatTime[0], minutes: repeatTime[1] })
  }

  return then
}

export { nextSpawn }
